// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBxwNPF5y_3Vjgiy-cjvp5F48VLIejVOls",
  authDomain: "devm7mdali.firebaseapp.com",
  projectId: "devm7mdali",
  storageBucket: "devm7mdali.appspot.com",
  messagingSenderId: "881397182613",
  appId: "1:881397182613:web:c224670a13a88ea1422b9c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app 